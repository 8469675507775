import * as Sentry from "@sentry/vue"
import {BrowserTracing} from "@sentry/tracing"
import {CaptureConsole as CaptureConsoleIntegration} from "@sentry/integrations"
import {router} from "@/router"

export default {
  install(app) {
    if (["staging", "production"].includes(import.meta.env.VITE_ENVIRONMENT)) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `platformaone-admin@${import.meta.env.VITE_VERSION}`, // TODO: start using semver
        environment: import.meta.env.VITE_ENVIRONMENT,
        debug: false,
        tracesSampleRate: 1.0,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            attachProps: true,
            logErrors: true, // passthrough to Vue logError handler
          }),
          new CaptureConsoleIntegration({
            // levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ["error", "warn"],
          }),
        ],
      })
    }
  },
}
