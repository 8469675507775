<template>
  <div class="w-full text-center px-2 pt-2 fixed z-50 flex-center" v-if="updateAvailable">
    <p class="text-white bg-danger rounded-md py-2 px-4">
      {{ Langs.get("version-check-new-version-available") }}
      <a class="!text-white underline font-bold cursor-pointer" @click="reload()">{{
        Langs.get("version-check-reload")
      }}</a>
    </p>
  </div>
</template>

<script>
import Langs from "@/langs"
import axios from "axios"

export default {
  name: "VersionCheck",
  data() {
    return {
      updateAvailable: false,
      currentVersion: "__VITE_VERSION__",
      latest: {},
      environment: import.meta.env.VITE_ENVIRONMENT,
      Langs,
    }
  },
  created() {
    this.fetchLatest()
  },
  computed: {},
  methods: {
    async fetchLatest() {
      if (this.environment != "production") return // bundles are hashed only in production
      // console.log('fetchLatest()')
      let response = await axios.get(
        `https://storage.googleapis.com/platformaone-files-${this.environment}/app_admin_latest`,
        {withCredentials: false}
      )

      // console.log('response', response.data)
      // console.log('env', import.meta.env)

      this.latest = response.data
      this.checkVersion()
    },
    checkVersion() {
      if (this.currentVersion != this.latest.commitShortSha) this.updateAvailable = true
      else {
        setTimeout(() => {
          this.fetchLatest()
        }, 1000 * 60 * 5) // every 5 minutes
      }
    },
    reload() {
      // console.log('reload()')
      window.location.reload()
    },
  },
}
</script>
