<template>
  <div
    id="app"
    :class="[
      {'currently-editing-input': currentlyEditingInput},
      {'user-is-touching-screen': userIsTouchingScreen},
      {'currently-adding-new-page': currentlyAddingNewPage},
      {'virtual-keyboard-visible': userIsTouchingScreen && currentlyEditingInput},
    ]"
    @eventtriggered="currentlyEditingInput"
  >
    <VersionCheck />

    <RouterView class="app-router-view" />

    <Modal v-if="modal.visible" @update:close="closeModelDetail()" :size="'D'">
      <template v-slot:header> </template>

      <template v-slot:body>
        <div id="dialog-message">
          {{ modal.message }}
        </div>
      </template>

      <template v-slot:footer>
        <a
          href="javascript:"
          class="button"
          v-for="(button, index) in modal.buttons"
          :key="`button-${index}`"
          v-bind:class="{primary: button.primary}"
          @click="modalExecute(button)"
          >{{ button.text }}</a
        >
      </template>
    </Modal>

    <DevelopmentPanel v-if="showDevelopmentPanel" />
  </div>
</template>

<script>
import Modal from "./components/ModalPanel.vue"
import VersionCheck from "./components/VersionCheck.vue"
import {useAppStore} from "@/stores/app.js"
import {defineAsyncComponent} from "vue"
import {storeToRefs} from "pinia"

export default {
  name: "App",
  components: {
    Modal,
    VersionCheck,
    DevelopmentPanel: defineAsyncComponent(() => import("@/components/DevelopmentPanel.vue")),
  },
  setup() {
    // stores
    const appStore = useAppStore()

    // states
    const {modal} = storeToRefs(appStore)

    // actions
    const {modalExecute} = appStore

    return {
      modal,
      modalExecute,
    }
  },
  data() {
    return {
      currentlyEditingInput: false,
      currentlyAddingNewPage: false,
      userIsTouchingScreen: false,
    }
  },
  computed: {
    showDevelopmentPanel() {
      return import.meta.env.VITE_ENVIRONMENT == "local_dev"
    },
  },
  created() {
    // detect user touching (actively using a touch device)
    window.addEventListener("touchstart", this.userIsTouchingScreenTrigger, false)

    // set CSS --window-inner-height var
    var root = document.documentElement
    root.style.setProperty("--window-inner-height", window.innerHeight + "px")

    // update CSS --window-inner-height var on window resize (eg. iPhone X scroll, when bottom bar is hidden)
    window.addEventListener("resize", () => {
      root.style.setProperty("--window-inner-height", window.innerHeight + "px")
    })
  },
  methods: {
    setCurrentlyEditingInputClass(state) {
      this.currentlyEditingInput = state
    },
    setCurrentlyAddingNewPageClass(state) {
      this.currentlyAddingNewPage = state
    },
    userIsTouchingScreenTrigger() {
      // console.log('user is touching screen')
      this.userIsTouchingScreen = true
      window.removeEventListener("touchstart", this.userIsTouchingScreenTrigger, false)
    },
  },
}
</script>

<style lang="less">
@import "variables.less";
@import "fonts/fonts.less";
:root {
  --window-inner-height: 0;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  margin: 0;
  position: relative;
  /* padding-bottom: 6rem; */
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

h2 {
  font-size: 24px;
  letter-spacing: 1.5px;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

.draggable {
  touch-action: none;
  user-select: none;
  cursor: default;
}

:focus {
  outline: 0;
}

.code {
  font-family: Menlo, monospace;
  font-size: 9pt;
  white-space: pre;
}

.info-has-draft {
  padding: 15px 0 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 8px;
  letter-spacing: 1.25px;
  font-weight: 700;

  span {
    background: #ff9900;
    padding: 1px 6px;
    border-radius: 4px;
  }
}

// Global animations

// rotating - used when loading
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromTop {
  0% {
    margin-top: -100px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

#app {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // remove grey highlight box on tap in iOS Safari
  height: 100%;
  min-width: 320px;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  a {
    color: #2a313c;
  }

  // buttons
  a.pfm-button {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: @pfm-button-primary-background;
    color: @pfm-button-primary-color;
    padding: 19px 25px 19px 25px;
    font-size: 0.875rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.5s;

    &.icononly {
      align-items: center;
      padding: 9px 0 9px 0;

      svg {
        //   width: 34px;
        //   height: 34px;

        & * {
          //stroke: #2A313C;
          //   fill: #ffffff;
        }
      }
    }

    &.disabled {
      background-color: @pfm-color-neutral-500 !important;
      cursor: not-allowed;
    }

    &.loading,
    &.working {
      padding-top: 16px;
      padding-bottom: 16px;
      background-color: @pfm-button-primary-background-hover !important; // important to disable hover
      cursor: wait;

      .animation-wrap.loading {
        display: flex;
        svg {
          animation: spin 1s linear infinite;
          width: 24px;
          height: 24px;
        }
      }
    }

    &.done,
    &.success {
      padding-top: 11px;
      padding-bottom: 11px;

      .icon-wrap.done {
        display: flex;

        svg {
          width: 34px;
          height: 34px;
        }
      }
    }

    &.success {
      background-color: @pfm-color-success !important;
    }

    &.failed {
      background-color: @pfm-color-danger;
    }

    &:hover {
      background-color: @pfm-button-primary-background-hover;
      transition: background-color 0.5s;
    }

    &.failed {
      &:hover {
        background-color: @pfm-color-danger;
      }
    }

    &:active {
      background-color: @pfm-button-primary-background;
      transition: background-color 0.5s;
    }

    &.light {
      background: @pfm-button-light-background;
      color: @pfm-button-light-color;

      svg {
        & * {
          //stroke: #2A313C;
          fill: @pfm-color-neutral-900;
        }
      }
    }

    &.danger {
      background: @pfm-color-danger;
    }

    &.icon {
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      align-items: center;
      padding: 18px 25px;

      svg {
        width: 20px;
        height: 20px;

        & * {
          //stroke: #2A313C;
          fill: @pfm-button-primary-icon;
        }
      }
    }

    svg {
      // height: 20px;
      // width: auto;

      & * {
        fill: #ffffff;
      }
    }

    span {
      padding-left: 6px;
    }

    &.shake {
      animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
    }

    &.secondary {
      background: none;
      border: 2px solid #2a313c;
      color: @pfm-button-secondary-color;
    }

    &.tertiary {
      background: none;
      border: none;
      color: @pfm-color-neutral-500;
    }

    &.naked {
      background: none;
      border: none;
      color: @pfm-color-neutral-900;
      font-size: 1.5rem;
      text-transform: lowercase;
      padding: 10px 0;
      display: inline-block;
      width: auto;

      &.disabled {
        background: none !important;
        color: @pfm-color-neutral-400;

        &:hover {
          color: @pfm-color-neutral-400;
        }
      }

      span {
        padding: 0 !important;
      }

      &:hover {
        color: @pfm-color-neutral-700;
      }
    }
  }

  .buttons {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  // hide navigation when editing field on touch devices (keyboard is visible)
  @media (pointer: coarse) {
    &.currently-editing-input {
      .view-menu {
        display: none;
      }

      #page-form {
        margin-top: 0;

        .page-form-inner {
          .button-commands {
            // display: none; // hide publish button when editing
          }
        }
      }
    }
  }

  // Messages to user
  .msg-tap-block-to-edit {
    display: none;
  }
  .msg-click-block-to-edit {
    display: block;
  }

  &.user-is-touching-screen {
    .msg-tap-block-to-edit {
      display: block;
    }

    .msg-click-block-to-edit {
      display: none;
    }
  }

  #guest-signup-view,
  #login-view,
  #publisher-view,
  #user-view,
  #user-change-password {
    width: 90%;
    min-width: 300px;
    max-width: 350px;
    margin: 0 auto;
    height: 100vh;

    p.welcome {
      font-size: 24px;
      padding-bottom: 60px;
      letter-spacing: 1.5px;
      line-height: 30px;
      text-align: left;

      @media (min-width: 375px) {
        text-align: center;
      }
    }

    .input-wrap {
      position: relative;
      margin-bottom: 10px;

      input {
        border: none;
        background: #ffffff;
        border-radius: 8px;
        // box-shadow: 6px 6px 30px 0 rgba(0, 0, 0, 0.06);
        background: #ffffff;
        box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.08);
        padding: 24px 25px 14px 20px;
        font-size: 16px;
        letter-spacing: 1.5px;
        width: 100%;

        &.invalid {
          border: 1px solid #fa586c;
          padding: 23px 24px 13px 19px;
        }
      }

      .error-message {
        color: #fa586c;
        padding: 5px 20px 8px;
        font-size: 14px;
      }

      label {
        text-transform: uppercase;
        position: absolute;
        top: 21px;
        left: 20px;
        font-size: 12px;
        font-weight: 400;
        color: #707a89;
        letter-spacing: 1.5px;
        transition: all 0.1s ease-in;
        pointer-events: none;
      }

      &.active {
        label {
          top: 12px;
          font-size: 10px;
        }
      }
    }

    .help-wrap {
      text-align: center;
      padding-top: 24px;
      color: #acacac;

      a {
        text-align: center;
        // text-decoration: underline;
        color: #acacac;
        padding: 0 10px;
      }
    }
  }

  #admin-settings-view,
    #page-settings-view,
    #site-settings-view,
    .pages-view,
    // .settings-view,
    .site-selection-view {
    width: 90%;
    margin: 0 auto;

    @media (min-width: 415px) {
      width: 375px;
      padding: 0;

      #pages-side-panel {
        width: 100%;
      }
    }
  }

  // .header {
  //     // padding: 30px 0 20px;
  //
  //     h2 {}
  //
  //     span {
  //         font-size: 16px;
  //         color: @pfm-color-neutral-800;
  //         font-weight: 400;
  //         padding-bottom: 60px;
  //         letter-spacing: 0.6px;
  //         line-height: 30px;
  //         text-align: left;
  //     }
  // }

  // .app-main,
  // .app-router-view,
  // .designer-view,
  // // .pages-view,
  // .selected-page-view,
  // .site-main-view,
  // .site-root-view {
  //     height: 100%;
  //     overflow-x: scroll;
  // }

  // Slider
  .slider {
    flex: 0 0 0;

    .draggable {
      padding: 8px 0;
      cursor: row-resize;

      .slider-handle {
        width: 40px;
        height: 4px;
        background: #d0d0d0;
        border-radius: 2px;
        margin: 0 auto;
      }
    }

    &:hover {
      .slider-handle {
        background: #8a8a8a;
      }
    }

    &.vertical .slider-handle {
    }

    &.horizontal {
      display: none;
      justify-content: center;
      align-items: center;
      background: #2a313c;

      @media (min-width: 768px) {
        display: flex;
      }

      .draggable {
        padding: 0 4px;
        cursor: col-resize;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .slider-handle {
          width: 4px;
          height: 40px;
        }
      }
    }
  }
}

.designer-view,
.selected-page-view {
  @media (min-width: 768px) {
    height: 100vh;
    display: flex;
    flex-direction: row-reverse;
  }
}

// Page preview
#poba-page-preview-main {
  // height: calc(100% - 80px);
  height: calc(var(--window-inner-height) - 80px);

  &.full-height {
    height: var(--window-inner-height);
  }

  @media (min-width: 768px) {
    height: 100%;
    position: inherit;
    flex: 1 1 0;
  }
}

.pages-view {
  #pages-side-panel {
    position: relative;
    box-shadow: none;
    background: none;

    // @media (min-width: 768px) {
    //   height: 100%;
    // }
  }
}

.page-form-field-instance {
  .page-form-field-wrap {
    margin: 15px 0;
    padding: 10px 10px 10px 15px;
    background: @pfm-color-neutral-200; //#EFEFEF;
    border-radius: 12px;
    position: relative;

    &:first-child {
      margin-top: 0;
    }

    &.content-invalid {
      border: 1px solid #fa586c;
      padding: 9px 9px 9px 14px; // fix for border
    }

    .label,
    .langs-available,
    label {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 10px;
      text-align: left;
      color: #2a313c;
      letter-spacing: 1.75px;
    }

    .label.tiny {
      font-size: 8px;
      letter-spacing: 1.25px;
      color: @pfm-color-neutral-700;

      &.danger {
        color: @pfm-color-danger;
      }
    }

    label {
      display: block;
    }

    .label.lang-label {
      color: #90949a;
      display: none;
      padding-right: 4px;
      flex: 0 0 auto;
    }

    .page-form-field-outer {
      // if all languages are shown
      &.field-all-langs {
        .label.lang-label {
          display: block;
        }

        .lang-wrap {
          padding: 15px 0;
          border-top: 1px solid #d0d0d0;

          // move lang label to the right
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          justify-content: space-between;

          &:first-child {
            border-top: none;
          }

          .simple-field-wrap {
            min-width: 0;
            flex-grow: 1;
          }

          .label.lang-label {
            flex: 0 0 auto;
          }

          .page-form-field {
            min-width: 90%;
          }
        }
      }

      .field-header {
        text-align: left;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .field-language {
          // position: absolute;
          // right: 0;
          // top: 0;
          // padding: 10px;
          margin-left: auto;
          display: flex;

          &.open {
            .langs-available {
              display: flex;
            }

            a.btn-lang-toggle {
              span.label {
                display: none;
              }

              svg * {
                fill: #dbdbdb;
              }
            }
          }

          .langs-available {
            display: none;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            a {
              color: #90949a;
              padding: 6px;
              margin: 0 4px;

              &.active {
                background: #ffffff;
                border-radius: 4px;
              }
            }
          }

          a.btn-lang-toggle {
            display: flex;
            padding-top: 2px;
            align-items: center;

            span.label {
              padding-right: 2px;
            }
          }
        }
      }

      .page-form-field {
        text-align: left;
        font-size: 16px;
        // margin-top: 10px;
        // padding: 0 45px 0 0;
        padding: 0 15px 0 0;

        .form-field {
          &.input,
          &.textarea {
            padding: 6px 0;
          }

          &.input {
          }

          &.textarea {
            overflow-x: auto;
            display: block;
            white-space: pre-wrap;
          }
        }

        .field-wrap {
          .select-field {
            // background: #D8D8D8;
            border: 1px solid #d8d8d8;
            border-radius: 8px;
            margin: 6px 0 0;
            position: relative;
            cursor: pointer;

            .select-items ul.select-item,
            .select-overlay,
            .selected-value {
              padding: 4px 8px;
            }

            .selected-value {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;

              span {
                // color: #90949A;
              }

              svg {
                width: 16px;
                height: 16px;
              }
            }

            .select-overlay {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
            }

            .select-items {
              ul.select-item {
                margin: 0;
                padding: 0 inherit;

                li {
                  padding: 8px 0;

                  &:first-child {
                    border-top: 1px solid #ececec;
                  }
                }
              }
            }
          }
        }
      }
    }

    .dropbox {
      border: 2px dashed #dbdbdb;
      margin: 10px 0;
      padding: 10px;
      border-radius: 8px;
      min-height: 20px;
      position: relative;

      * {
        cursor: pointer;
      }

      input.input-file {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
      }

      p.upload-new {
      }

      p.file-name {
        text-align: center;
      }

      .uploading {
        text-align: center;

        .loading-wrap {
          svg {
            animation: spin 1s linear infinite;
            width: 30px;
            height: 30px;
          }
        }

        span.label {
          font-weight: 700;
          text-transform: uppercase;
          font-size: 10px;
          color: #acacac;
          letter-spacing: 1.75px;
        }
      }

      .icon-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          width: 26px;
          height: 26px;

          * {
            fill: @pfm-color-neutral-700;
          }
        }

        span.label {
          padding-top: 4px;
        }

        &.hidden {
          display: none;
        }
      }

      .langs-wrapper {
        text-align: center;

        .label {
          color: #90949a;
        }
      }
    }
  }

  &.each,
  &.fonts {
    .page-form-field-wrap {
      padding: 15px 10px;

      .page-form-subfield {
        .label {
          // padding-left: 5px;
        }
      }

      .page-form-field-list-item {
        background: #f6f6f6;
        border-radius: 8px;
        border: 1px solid @pfm-color-neutral-200;
        // padding: 10px;
        margin: 10px 0 0;
        position: relative;

        .form-sub-items {
          padding: 10px;
          width: 100%;

          .field-header {
            label {
              // display: none;
            }
          }
        }

        .page-form-field-list-item-menu {
          border-top: 1px solid #ececec;
          padding: 8px 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          a.button {
            padding: 0 6px;

            svg {
              width: 26px;
              height: 26px;
            }

            span.label {
              padding-top: 4px;
            }
          }
        }

        .page-form-field {
          .field-wrap {
            // padding: 10px 0 10px 0;
            // move lang label to the right
            padding: 0 0 20px;

            &:last-child {
              padding-bottom: 0;
            }
          }
        }
      }

      .each-footer,
      .fonts-form-footer {
        padding: 15px 0 0;

        a.button.add-item {
          .icon-wrap {
            background: #90949a;
            border-radius: 20px;
            padding: 4px 20px;

            svg {
              display: flex;
              width: 26px;
              height: 26px;
            }

            svg * {
              fill: @pfm-color-neutral-100;
            }
          }

          span.label {
            padding: 6px 0 0;
          }
        }
      }
    }
  }

  &.each,
  &.img,
  &.link {
    .lang-wrap {
      padding: 5px 0 0;

      .container {
        .image-version {
          background: #f6f6f6;
          border-radius: 8px;
          border: 1px solid @pfm-color-neutral-200;
          padding: 10px;
          margin: 10px 0 0;

          .upload-wrap {
            position: relative;
            text-align: center;
            display: flex;
            flex-direction: row-reverse;

            input.input-file {
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              margin: 0;
              cursor: pointer;
            }

            .langs-wrapper {
              text-align: right;
              // position: absolute;
              // right: 0;
              // top: 0;
              display: flex;

              .label {
                color: #90949a;
                padding: 0 2px 0 8px;
              }
            }

            .img-wrap {
              flex-grow: 1;
            }

            img {
              min-height: 150px;
              max-height: 200px;
              max-width: 100%;
              width: auto;
              z-index: 100;
              position: relative;
              pointer-events: none;
            }

            .loading {
              position: absolute;
              top: 30px;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 50;
              pointer-events: none;

              svg {
                animation: spin 1s linear infinite;
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        // .langs-wrapper {
        //     position: absolute;
        //     right: 15px;
        //     top: 15px;
        // }
        // .langs-wrapper span {
        //     padding-right: 5px;
        // }
        // .next-step {
        //     padding: 10px;
        // }
        //

        .next-step {
          border-top: 1px solid #ececec;
          padding: 15px 0 5px;

          .icon-wrap {
            background: #90949a;
            border-radius: 20px;
            padding: 4px 20px;

            svg {
              display: flex;
              width: 26px;
              height: 26px;

              #Line-9,
              #Line-9-Copy {
                stroke: #efefef;
              }
            }

            svg * {
              fill: #efefef;
            }
          }

          span.label {
            padding-top: 4px;
          }

          .lang-selection {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            a.button {
              padding: 4px 12px;
              margin: 0 6px;
              background: #90949a;
              border-radius: 20px;
              color: #efefef;
              font-weight: 700;
              letter-spacing: 1.25px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
// }
// }
// }

// Administration panel
#pages-side-panel {
  pointer-events: initial;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px; // also set min height in Slider.vue
  z-index: 100;
  background: #ffffff;
  border-radius: 0;
  transition: border-radius 0.2s ease-in-out 0s;
  box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: center;

  &.block-selected {
    height: 50%;
    border-radius: 20px 20px 0 0;
    min-width: 320px;

    @media (min-width: 768px) {
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    // width: inherit;
    width: 320px;
    height: 100%;
    position: inherit;
    border-radius: 0;
    box-shadow: none;

    .slider.vertical {
      .slider-handle {
        display: none;
      }
    }
  }

  a.button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.danger {
      svg * {
        fill: #fa586c;
      }

      span.label {
        color: #fa586c;
      }
    }

    span.label {
      font-weight: 700;
      font-size: 12px;
      letter-spacing: 1.2px;
      text-transform: uppercase;
    }
  }

  // Page form - content editor
  #page-form {
    // height: 1000px;
    flex: 1 1 0;
    overflow-y: scroll;
    pointer-events: auto;
    margin-top: 30px;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .page-form-inner {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .form-view {
        // padding-bottom: 60px;
        // .block-container {
        //     padding: 30px 0 0;
        //
        //     &:first-child {
        //         padding-top: 0;
        //     }
        //
        //     &:last-child {
        //         padding-bottom: 0;
        //     }

        // .form-block-content {
        // .block-field-item {

        // submenu used in designer panel
        .view-sub-sub-menu {
          text-align: center;
          padding: 10px 0 5px;
          border-bottom: 1px solid #efefef;
          margin-bottom: 30px;

          span.selected-view-label {
            padding: 0 6px;
            text-transform: uppercase;
            font-size: 10px;
            letter-spacing: 1.5px;

            &.active {
              font-weight: 700;
            }
          }
        }
      }

      .button-commands {
        padding-bottom: 10px;

        &.sticky {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1000;
          width: calc(100% - 20px);
          margin: 0 auto;
          padding-top: 10px;
          background: #ffffff;
        }

        a.btn-publish {
          width: 100%;
          background: #3c3c3c;
          text-transform: uppercase;
          text-align: center;
          display: block;
          color: #aeaeae;
          font-size: 12px;
          font-weight: 700;
          border-radius: 8px;
          letter-spacing: 1.75px;
          transition: background-color 0.5s ease;

          &:active {
            background: #1a1a1a;
          }

          &.failed {
            background: #fa586c;
            color: #ffffff;
          }

          &.published {
            background: #74d202;
            color: #ffffff;
          }

          .loading {
            padding: 13px 0;

            svg {
              animation: spin 1s linear infinite;
              height: 26px;
              width: 26px;
            }
          }

          span {
            display: inline-block;
            padding: 20px 0;
          }
        }
      }
    }
  }
}
</style>
